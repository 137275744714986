/** include framework dependencies **/

@import './../../framework/_Factories';
@import './../../framework/_AbstractClasses';
@import './../../framework/_Constants';

/** end include dependencies **/

/** include sections **/

$guideTitle:#0f1d41;

@import './../../sections/general/header';
@import './../../sections/general/page_header';


/** end include sections **/
$decoratorBackground:#f3f3f3;
$mainCountryDataBackground:#e2175f;
$selectedCountryLabel:#0c1e3f;
$countryParagraph:#58585a;
.main-info{
	margin-bottom: $fullMargin;
	padding-bottom: $fullPadd;
	border-bottom:3px solid $mainCountryDataBackground;
	.photo-container{
		img{
			top:0;
			left:20px;
			z-index:2;
		}
		.decorator{
			background:$decoratorBackground;
			height:65px;
			right:0;
		}
		.decorator1{
			top:0;
			width:274px;
		}
		.decorator2{
			top:80px;
			width:197px;
		}
		.decorator3{
			top:160px;
			width:117px;
		}
		.decorator4{
			top:240px;
			width:197px;
		}
		.decorator5{
			top:320px;
			width:274px;
		}

	}
	.country-info{
		padding:0 $halfPadd;
		h3{
			@extend .RhombusTitleAbstract;
		}
		p{
			@include textDecorator($countryParagraph, $latoregular, 14px, 0 0 15px 0);
			line-height: 16px;
			.selected{
				color:$selectedCountryLabel;
				font-family:$latobold;
			}
			&.great-op{
				@include textDecorator($selectedCountryLabel, $latobold, 14px, 0);
				@include rectangle(100%, 60px);
				background:$decoratorBackground;
				padding:22px 12px;
				line-height:18px;
				text-transform: uppercase;
			}
		}
	}
	.main-country-data{
		padding:0 $halfPadd;
		h3{
			@extend .RhombusTitleAbstract;
		}
		.country-informations-list{
			li{
				background:$mainCountryDataBackground;
				padding:3px $halfPadd 3px;
				margin-bottom: 1px;
				p{
					@include textDecorator(white, $latoregular, 14px, 0 0 0 0);
					line-height:16px;
					&.country-info-title{
						@include textDecorator(white, $latobold, 14px, 0 0 0 0);
						line-height:16px;
					}
				}
			}
		}
	}

}

/** start forecast and highlights section **/
$forecastTitle:#0c1e3f;
$forecastText:#58585a;
.forecast-and-highlights{
	margin-bottom: $fullMargin;
	.forecast{
		padding: 0 $halfPadd;
		h4{
			@extend .RhombusTitleAbstract;
			color:$forecastTitle;
			&:before{
				background:$forecastTitle;
			}
		}
		p{
			@include textDecorator($forecastText, $latoregular, 14px, 0 0 $halfPadd 0);
			line-height:18px;
		}
	}
	.highlights{
		padding: 0 $halfPadd;
		h4{
			@extend .RhombusTitleAbstract;
			color:$forecastTitle;
			&:before{
				background:$forecastTitle;
			}
		}
		p{
			@include textDecorator($forecastText, $latoregular, 14px, 0 0 5px 0);
			line-height:18px;
			.selected{
				font-family:$latobold;
				color:$forecastTitle;
				text-transform: uppercase;
			}
		}
	}
}

/** end forecast and highlights section **/


/** start economic indicators section **/

$tableHeadBackground:#0c1e3f;
$indicatorColor:#58585a;
$evenBackground:#f3f3f3;
$tableBorder:#f3f3f3;

.economic-indicators{
	margin-bottom: $fullMargin;
	padding: 0 $halfPadd;
	h3{
		@extend .RhombusTitleAbstract;
		color:$forecastTitle;
		&:before{
			background:$forecastTitle;
		}
	}
	.economic-indicators-table{
		thead{
			background:$tableHeadBackground;
			border:1px solid $tableHeadBackground;
			tr{
				td{
					padding: 25px 0;
					p{
						@include textDecorator(white, $latoregular, 14px, 0 0 5px 0);
						line-height: 18px;
						text-transform: uppercase;
						text-align: center;
					}
				}
			}
		}
		tbody{
			border:1px solid $tableBorder;
			.odd{
				td{
					background:white;
				}
			}
			.even{
				td{
					background:$evenBackground;
				}
			}
			tr{
				td{
					padding:25px 0;
					p{
						@include textDecorator($indicatorColor, $latoregular, 14px, 0 0 5px 0);
						line-height: 18px;
						text-transform: uppercase;
						text-align: center;
					}

				}
			}
		}
	}

}
/** end economic indicators section **/

/** start business info section **/
$bleumarineTitle:#0c1e3f;
$redTitle:#d9232e;
$businessInfoText:#58585a;
.business-info{
	.info-list-container{

		.hold-together{
			@include rectangle(1px, 780px);
			position: absolute;
			top: 3px;
			left: 22px;
		}

		&.red{
			.hold-together{
				background:$redTitle;
			}
		}

		&.bleumarine{
			.hold-together{
				background:$bleumarineTitle;
			}
			h6{
				color:$bleumarineTitle;
				&:before{
					background:$bleumarineTitle;
				}
			}
		}

	}

	.info-list{
		padding:0 $halfPadd;
		li {
			margin-bottom: $fullMargin;
		}

		h6{
			@extend .RhombusTitleAbstract;
		}
		p{
			@include textDecorator($businessInfoText, $latoregular, 14px, 0 0 5px 0);
			line-height: 18px;
			padding-left: 25px;
		}
	}
}
/** end business info section **/
